<!--
  服务集成商详情（机构管理员）
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="集成商认证信息（机构管理员）" />
    <div class="detail_main page_width" v-loading="loading">
      <div class="main_content">
        <info-header title="集成商认证信息" />
        <div style="padding: 20px 0;">
          <!-- 服务用户申请 -->
          <info-item
            :label-width="labelWidth"
            label="机构名称"
            :value="formData.organization.name">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="从事所选科技服务年限"
            :value="formData.organization.workingYears">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="技术转移示范机构"
            :value="formData.organization.technologyTransfer">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="专业技术转移服务平台"
            :value="formData.organization.technologyTransferPlatform">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="人员情况"
            :value="personnelSituation">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="机构技术经纪人数量"
            :value="brokersNumber">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="是否有科技服务章程"
            :value="formData.organization.serviceCharter">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="是否有客户管理服务规范"
            :value="formData.organization.customerManagementStandard">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="是否开展国际技术转移合作"
            :value="formData.organization.technologyTransferCooperation">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="服务对象群体"
            :value="formData.organization.targetGroups">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="经济社会效益"
            :value="formData.organization.socialResults">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="社会信誉"
            :value="formData.organization.socialReputation">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="典型服务案例"
            :value="formData.organization.serviceCase">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="经营理念"
            :value="formData.organization.managementIdea">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="经营条件"
            :value="formData.organization.operatingConditions">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="上年度技术服务情况"
            :value="formData.organization.lastYearTechnicalServices">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="上年度财务收入情况"
            :value="formData.organization.lastYearFinancialIncome">
          </info-item>
          <info-item
            :label-width="labelWidth"
            label="附件"
            value-type="file-list"
            :value="formData.organization.attachmentDocument ? JSON.parse(formData.organization.attachmentDocument) : []">
          </info-item>
        </div>
        <!-- 被驳回 -->
        <template v-if="formData.certificationStatus === globalVar.CERTIFICATION_STATUS_REJECT">
          <info-header title="驳回信息" />
          <div style="padding: 20px 0;">
            <info-item
            :label-width="labelWidth"
            label="驳回原因"
            :value="formData.reason">
          </info-item>
          <!-- 已驳回状态下显示重新提交按钮 -->
          <el-row>
            <el-button
              type="primary"
              size="small"
              @click="orgCertification">
              重新申请
            </el-button>
          </el-row>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      labelWidth: '180px',
      // formData: {},
      btnLoading: false
    }
  },
  computed: {
    formData () {
      return this.userInfo
    },
    // 人员情况
    personnelSituation () {
      if (this.formData.organization.personnelSituation) {
        const nums = this.formData.organization.personnelSituation.split(',')
        return `总人数：${nums[0]}人，专职从事技术转移人员：${nums[1]}人，硕士（含以上）：${nums[2]}人，大学本科：${nums[3]}人，大专及（以下）：${nums[4]}人，高级职称：${nums[5]}人，中级职称：${nums[6]}人`
      } else {
        return ''
      }
    },
    // 机构技术经纪人数量
    brokersNumber () {
      if (this.formData.organization.brokersNumber) {
        const nums = this.formData.organization.brokersNumber.split(',')
        return `高级：${nums[0]}人，中级：${nums[1]}人，初级：${nums[2]}人`
      } else {
        return ''
      }
    }
  },
  methods: {
    /**
     * 认证成为集成商
     *  + 如果是（法人/非法人）入驻的服务机构则跳转aggregator-admin
     *  + 如果是挂靠入驻的服务机构则跳转
     */
    orgCertification () {
      const { organizationRole, certificationStatus } = this.formData
      // 机构角色， 0 - 挂靠关系 / 1 - 法人关系
      if (organizationRole) {
        // certificationStatus: 0 - 未申请, 1 - 待处理, 2 - 已通过, 3 - 已驳回
        if (certificationStatus === globalVar.CERTIFICATION_STATUS_REJECT) {
          this.$router.push('/org-audit/aggregator-admin/apply')
        }
      } else {
        // 0 - 未申请, 1 - 待处理, 2 - 已通过, 3 - 已驳回
        if (certificationStatus === globalVar.CERTIFICATION_STATUS_REJECT) {
          this.$router.push('/org-audit/aggregator-person/apply')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;
    }
  }
}
</style>
